import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PublicConstants } from '../public.constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class PublicService {
  supplierApiPath = PublicConstants.API_SUP_URL;
  getInviteApiPath = PublicConstants.API_SUP_URL_INVITE;
  individualRegApiPath = 'dummy';
  getUIconfigUrl = PublicConstants.API_SUP_URL_CONF;
  constructor(public snackBar: MatSnackBar, private httpClient: HttpClient) { }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 3000
    });
  }

  createSupplier(data): Observable<any> {
    return this.httpClient.post<any>(this.supplierApiPath, data);
  }
  register(data, registerFor): Observable<any> {
    if (registerFor === 'Supplier') {
      return this.httpClient.post<any>(this.supplierApiPath, data);
    } else {
      return this.httpClient.post<any>(this.individualRegApiPath, data);
    }
  }

  goToLogin() {
    let clientCode = '';
    if (window && window.location) {
      let url = window.location.href.replace(environment.baseContext, '');
      const temp = url.split('/');
      if (temp && temp.length >= 4) {
        clientCode = temp[4];
      }
      if (clientCode === 'landing') {
        clientCode = '';
      }
    }
    if (!clientCode) {
      clientCode = 'ev';
    }
    window.location.href = `${environment.baseContext}/${clientCode}`;
  }

  getUIconfig(): Observable<any> {
    return this.httpClient.get(this.getUIconfigUrl);
  }
  getInviteDetails(referralCode: string) {
    return this.httpClient.get(this.getInviteApiPath + '?referralCode=' + referralCode);
  }
}
