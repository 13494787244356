// *
// * ©2018 EdgeVerve Systems Limited, Bangalore, India. All Rights Reserved.
// *
// * The EdgeVerve proprietary software program ("Program"), is protected by copyrights laws, international treaties
// * and other pending or existing intellectual property rights in India, the United States and other countries. The Program
// * may contain / reference third party or open source components, the rights to which continue to remain with the applicable
// * third party licensors or the open source community as the case may be and nothing here transfers the rights to the third party
// * and open source components, except as expressly permitted. Any unauthorized reproduction, storage, transmission in any
// * form or by any means (including without limitation to electronic, mechanical, printing, photocopying, recording or 
// * otherwise), or any distribution of this Program, or any portion of it, may result in severe civil and criminal penalties, and will be
// * prosecuted to the maximum extent possible under the law.
// *
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { PublicRoutingModule } from './public/public-routing.module';

import { SharedModule } from 'ba-ui';
import { PublicService } from './public/services/public.service';

import { AppPublicComponent } from './app-public.component';
import { LandingPageComponent } from './public/components/landing-page/landing-page.component';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.baseContext}/public/public-assets/i18n/`, '.json');
}

@NgModule({
  declarations: [
    AppPublicComponent,
    LandingPageComponent
  ],
  imports: [
    PublicRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  bootstrap: [AppPublicComponent],
  providers: [PublicService]
})
export class AppPublicModule { }
