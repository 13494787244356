/****************************************************************************************************************
* ©2018 EdgeVerve Systems Limited, Bangalore, India. All Rights Reserved.
*
* The EdgeVerve proprietary software program ("Program"), is protected by copyrights laws, international treaties and
* other pending or existing intellectual property rights in India, the United States and other countries. The Program
* may contain / reference third party or open source components, the rights to which continue to remain with the
* applicable third party licensors or the open source community as the case may be and nothing here transfers the
* rights to the third party and open source components, except as expressly permitted. Any unauthorized reproduction,
* storage, transmission in any form or by any means (including without limitation to electronic, mechanical, printing,
* photocopying, recording or otherwise), or any distribution of this Program, or any portion of it, may result in
* severe civil and criminal penalties, and will be prosecuted to the maximum extent possible under the law.
*****************************************************************************************************************/

import { environment } from "../../environments/environment";

export class PublicConstants {
  public static API_BASE_URL = '/authapi';
  public static API_COMMON_URL = `${environment.baseContext}/commonapi`;
  public static API_SUP_URL = `${environment.baseContext}/guest/supapi/suppliers`;
  public static API_SUP_URL_INVITE = `${environment.baseContext}/guest/commonapi/invitation`;
  public static API_SUP_URL_CONF = `${environment.baseContext}/guest/uiconfig`;
  public static REFERENCEDATA_BASE_URL = '/referenceData';
  public static EMAIL_REGEX = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
  public static MOBILE_REGEX = '^([0-9+-]){10,13}$';
  public static PINCODE_REGEX = '^([0-9]){4,6}$';
  public static DUNS_REGEX = '^([0-9]){3,9}$';
  public static LANGUAGE = 'en_us';
  public static NAME_MAX_LENGTH = 50;
  public static SUPP_NAME_MAX_LENGTH = 50;
  public static PW_MIN_LENGTH = 8;
  public static ADR_MAX_LENGTH = 200;
  public static TEXT_REGEX = '^[0-9a-zA-Z ]*$';
  public static TEXT_MAX_LENGTH = 50;
  public static DESCRIPTION_MAX_LENGTH = 250;
  public static NUMBER_REGEX = '^[0-9]*$';
  public static NUMBER_MAX_LENGTH = 6;
  public static DECIMAL_REGEX = '^[0-9]{1,10}(\\.[0-9]{1,2})?$';
  public static PRICE_MAX_LENGTH = 11;
  public static REG_FOR = 'Supplier'; // Supplier or Individual
}
