import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PublicConstants } from '../../public.constants';
import { Router } from '@angular/router';
import { PublicService } from '../../services/public.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {

  constructor(private translate: TranslateService, private router: Router, private _service: PublicService) {
    translate.setDefaultLang(PublicConstants.LANGUAGE);
    translate.use(PublicConstants.LANGUAGE);
  }
  goToLogin() {
    this._service.goToLogin();
  }
}
