<!-- <app-register></app-register> -->
<!-- style="padding: 25%" -->
<div>
    <div>
        <button mat-raised-button color="primary" type="submit" (click)="goToLogin()" style="float: right; margin:15px;">
            {{'landing.login' |translate }}
        </button>
    </div>
    <div>
        <div class="public-textContainer">
            <div class="public-centerContainer">
                <div class="public-tag1 ">
                    <app-typewriter></app-typewriter>
                </div>
                <div class="public-tag2">NIA intelligence at its best to guide you</div>
            </div>
        </div>
    </div>

    <!-- <div>
        <button mat-raised-button color="primary" type="submit" (click)="goToRegister()" style="float: right;">
            {{'landing.register' |translate }}
        </button>
    </div> -->

</div>